import { Autocomplete } from '../lib/Autocomplete';

export class search {
    constructor() {
        this.search();
    }

    search(): void {
        // search form handling
        document.querySelectorAll<HTMLFormElement>('.search-form').forEach((searchForm) => {
            const formWrapper = searchForm.parentElement as HTMLElement;
            const openBtn = searchForm.querySelector<HTMLButtonElement>('.search-form__open') as HTMLButtonElement;
            const formInputWrapper = searchForm.querySelector<HTMLElement>('.search-form__wrapper') as HTMLElement;
            const input = searchForm.querySelector<HTMLInputElement>('.search-form__input') as HTMLInputElement;
            const submitBtn = formWrapper.querySelector<HTMLButtonElement>('.search-form__submit') as HTMLButtonElement;

            if (searchForm) {
                Autocomplete.create(formWrapper, searchForm, input);
            }

            openBtn?.addEventListener('click', () => {
                formInputWrapper.classList.toggle('open');
                submitBtn.style.zIndex = '0';
                openBtn.style.zIndex = '10';

                if (formInputWrapper.classList.contains('open')) {
                    input.focus();
                }
            });

            input.addEventListener('input', () => {
                if (input.value.length == 0) {
                    submitBtn.style.zIndex = '0';
                    openBtn ? (openBtn.style.zIndex = '10') : '';
                } else {
                    submitBtn.style.zIndex = '10';
                    openBtn ? (openBtn.style.zIndex = '0') : '';
                }
            });
        });
    }
}
