import GLightbox from 'glightbox';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

export class lightbox {
    private readonly selector: string;

    constructor(selector: string) {
        this.selector = selector;
    }

    isElementAvailable(): NodeList {
        return document.querySelectorAll(this.selector);
    }

    youtube(): void {
        if (this.isElementAvailable().length <= 0) {
            return void 0;
        }

        const elements = document.querySelectorAll(this.selector) as NodeList;

        if (elements.length > 0) {
            const lightbox = GLightbox({ selector: null });

            Array.from(elements as NodeListOf<HTMLElement>).forEach((element: HTMLElement) => {
                const content = document.getElementById(String(element.dataset.content));

                if (content) {
                    lightbox.insertSlide({
                        content: content,
                    });

                    lightbox.on('open', () => {
                        content?.classList.remove('d-none');
                    });

                    element?.addEventListener('click', (e) => {
                        e.preventDefault();
                        lightbox.open();
                    });
                }
            });
        }
    }

    image(): void {
        if (this.isElementAvailable().length <= 0) {
            return void 0;
        }

        const customLightboxHTML = `
            <div id="glightbox-body" class="glightbox-container" tabindex="-1" role="dialog" aria-hidden="false">
                <div class="gloader visible"></div>
                <div class="goverlay"></div>
                <div class="gcontainer">
                    <div id="glightbox-slider" class="gslider"></div>
                    <button class="gclose gbtn" aria-label="Close" data-taborder="3">{closeSVG}</button>
                    <button class="gprev gbtn" aria-label="Previous" data-taborder="2">{prevSVG}</button>
                    <button class="gnext gbtn" aria-label="Next" data-taborder="1">{nextSVG}</button>
                    <span id="gcount" class="gcount gbtn | py-vsg px-sg text-bg-white" aria-hidden="true"></span>
                </div>
            </div>`;

        // @ts-ignore
        const lightbox = GLightbox({
            selector: this.selector,
            lightboxHTML: customLightboxHTML,
            moreLength: 0,
        });

        // if there is a show all button available
        const showAllImagesBtn: HTMLCollectionOf<Element> = document.getElementsByClassName('js-lightbox');
        for (const _item of Array.from(showAllImagesBtn)) {
            const _btn = _item as HTMLButtonElement;
            const _galleryName = _btn?.dataset.gallery;

            _btn.addEventListener('click', () => {
                const _elems = document.querySelectorAll('a[data-gallery="' + _galleryName + '"]');
                const _open = _elems[0] as HTMLElement;
                _open.click();
            });
        }

        //set Count
        lightbox.on('open', () => {
            const counterEl = document.getElementById('gcount');
            if (counterEl) {
                const totalSlides = lightbox.elements.length;
                let currentIndex = lightbox.getActiveSlideIndex() + 1;
                counterEl.innerHTML = `${currentIndex} / ${totalSlides}`;
            }
        });

        lightbox.on('slide_before_change', () => {
            const totalSlides = lightbox.elements.length;
            const counterEl = document.getElementById('gcount');
            if (counterEl) {
                let currentIndex = lightbox.getActiveSlideIndex() + 1;
                counterEl.innerHTML = `${currentIndex} / ${totalSlides}`;
            }
        });
    }

    downloads(): void {
        if (this.isElementAvailable().length <= 0) {
            return void 0;
        }

        const elements = document.querySelectorAll(this.selector) as NodeList;

        if (elements.length > 0) {
            Array.from(elements as NodeListOf<HTMLElement>).forEach((element: HTMLElement) => {
                const lightbox = GLightbox({ selector: null });
                const content = document.getElementById(String(element.dataset.content));
                console.log(content);
                console.log(element);

                if (content) {
                    lightbox.insertSlide({
                        content: content,
                        height: 'auto',
                    });

                    element?.addEventListener('click', (e) => {
                        e.preventDefault();
                        lightbox.open();
                    });
                }
            });
        }
    }

    ajax(): void {
        if (this.isElementAvailable().length <= 0) {
            return void 0;
        }

        const _place = new URL(document.location.href).searchParams.get('place');
        const _lng = new URL(document.location.href).searchParams.get('lng');
        const _lat = new URL(document.location.href).searchParams.get('lat');

        const _btn = document.querySelector(this.selector) as HTMLButtonElement;
        const _url = new URL(_btn.dataset.mapUrl ?? '');
        _url.searchParams.append('place', _place ?? '');
        _url.searchParams.append('lng', _lng ?? '');
        _url.searchParams.append('lat', _lat ?? '');
        const ajax = GLightbox({
            selector: null,
            skin: 'maps',
            width: 'var(--maps-width)',
            height: 'var(--maps-height)',
            zoomable: false,
            draggable: false,
        });

        if (_url.href !== '') {
            const clonedElement = document.getElementById('plumberfinder-map')?.cloneNode(true);

            _btn.addEventListener('click', async () => {
                const response = await fetch(_url.href);
                await response
                    .json()
                    .then((data) => {
                        if (!data.pois.length) {
                            return;
                        }

                        const box = new google.maps.LatLngBounds();

                        for (let i = 0; i < data.pois.length; i++) {
                            data.pois[i].pos = new google.maps.LatLng(
                                parseFloat(data.pois[i].lat),
                                parseFloat(data.pois[i].lng)
                            );
                            box.extend(data.pois[i].pos);
                        }

                        const map = new google.maps.Map(document.querySelector('#maps2') as HTMLElement, {
                            zoom: 12,
                            center: box.getCenter(),
                        });

                        const infoWindow = new google.maps.InfoWindow({
                            content: '',
                            disableAutoPan: true,
                        });

                        const markers = data.pois.map((poi: any) => {
                            const marker = new google.maps.Marker({
                                position: poi.pos,
                                // icon: _icon,
                            });

                            // reconstruct the infowindow content due to linking in the inquiry process
                            const parser = new DOMParser();
                            const infoWindowContentBody = parser.parseFromString(poi.infoWindowContent, 'text/html');
                            const button = infoWindowContentBody.querySelector(
                                '.CE_PLUMBERFINDER_INFOWINDOW .address-list__map-inquiry'
                            ) as HTMLAnchorElement;
                            let infoWindowContent: any = poi.infoWindowContent;

                            if (button) {
                                const anchorElement = document.querySelector(
                                    '[data-poi-uid="' + poi.uid + '"]'
                                ) as HTMLAnchorElement;
                                if (anchorElement) {
                                    button.href = anchorElement.href;
                                    infoWindowContent =
                                        infoWindowContentBody.querySelector('.CE_PLUMBERFINDER_INFOWINDOW')?.outerHTML;
                                }
                            }

                            marker.addListener('click', () => {
                                infoWindow.setContent(infoWindowContent);
                                infoWindow.setPosition(poi.pos);
                                infoWindow.open(map, marker);

                                map.panTo(poi.pos);
                            });

                            return marker;
                        });

                        document.querySelectorAll<HTMLButtonElement>('[data-result]').forEach((btn) => {
                            const _id: number = parseInt(btn.dataset.result + '');

                            if (markers[_id]) {
                                btn.addEventListener('click', () => {
                                    google.maps.event.trigger(markers[_id], 'click');
                                });
                            }
                        });

                        const renderer = {
                            // @ts-ignore
                            render({ count, position }, stats) {
                                // change color if this cluster has more markers than the mean cluster
                                const color = count > Math.max(10, stats.clusters.markers.mean) ? '#ff0000' : '#0000ff';
                                // create svg url with fill color
                                const svg = window.btoa(
                                    `<svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240"><circle cx="120" cy="120" opacity=".6" r="70" /><circle cx="120" cy="120" opacity=".3" r="90" /><circle cx="120" cy="120" opacity=".2" r="110" /></svg>`
                                );
                                // create marker using svg icon
                                return new google.maps.Marker({
                                    position,
                                    icon: {
                                        url: `data:image/svg+xml;base64,${svg}`,
                                        scaledSize: new google.maps.Size(60, 60),
                                    },
                                    label: {
                                        text: String(count),
                                        color: 'rgba(255,255,255,0.9)',
                                        fontSize: '16px',
                                    },
                                    title: `Cluster of ${count} markers`,
                                    // adjust zIndex to be above other markers
                                    zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
                                });
                            },
                        };

                        // Add a marker clusterer to manage the markers.
                        new MarkerClusterer({ markers, map, renderer });

                        map.fitBounds(box);
                    })
                    .finally(() => {
                        const content = document.querySelector('#plumberfinder-map');
                        content?.classList.remove('d-none');
                        content?.classList.add('d-flex');

                        ajax.setElements([
                            {
                                content: content,
                            },
                        ]);

                        ajax.on('close', () => {
                            const body = document.querySelector('body');
                            body?.append(clonedElement ?? '');
                        });

                        // prevent infobox close button to close lightbox
                        document.querySelector('.plumberfinder-map__map')?.addEventListener('click', (e) => {
                            e.stopPropagation();
                        });

                        ajax.open();
                    });
            });
        }
    }
}
