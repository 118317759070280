export class video {
    private iconPlay: SVGUseElement;
    private iconPause: SVGUseElement;
    private video: HTMLVideoElement;

    constructor() {
        this.video = document.getElementById('hero-video') as HTMLVideoElement;
        const playButton = document.querySelector('.hero__video-controls-play') as HTMLButtonElement;
        this.iconPause = playButton?.querySelector('use:first-child') as SVGUseElement;
        this.iconPlay = playButton?.querySelector('use:last-child') as SVGUseElement;
        const videoWorks = !!this.video?.canPlayType;

        if (videoWorks) {
            const windowWidth = window.innerWidth;
            const mobileWidth: number = parseInt(this.video.dataset.mobileMaxWidth ?? '991');
            let source = document.createElement('source') as HTMLSourceElement;
            source.type = 'video/mp4';

            playButton.addEventListener('click', () => {
                if (this.video.paused || this.video.ended) {
                    this.video.play();
                    this.switchButtons();
                } else {
                    this.video.pause();
                    this.switchButtons();
                }
            });

            if (windowWidth <= mobileWidth) {
                source.src = this.video.dataset.mobile
                    ? this.video.dataset.mobile ?? ''
                    : this.video.dataset.desktop ?? '';
            } else {
                source.src = this.video.dataset.desktop ?? '';
            }

            this.video.appendChild(source);

            const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
            if (mediaQuery.matches) {
                setTimeout(() => {
                    this.video.pause();
                    this.switchButtons();
                }, 100);
            }

            this.observe(this.video);
        }
    }

    private switchButtons(): void {
        if (this.video.paused) {
            this.iconPlay.classList.remove('d-none');
            this.iconPause.classList.add('d-none');
        } else {
            this.iconPlay.classList.add('d-none');
            this.iconPause.classList.remove('d-none');
        }
    }

    private observe(elem: HTMLVideoElement): void {
        const playPauseObserver = new IntersectionObserver((item) => {
            const video = item[0].target as HTMLVideoElement;
            if (!item[0].isIntersecting) {
                video.pause();
            } else {
                if (video.paused) {
                    video.play();
                }
            }
        });

        playPauseObserver.observe(elem);
    }
}
