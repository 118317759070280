import { lightbox } from './lightbox';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

declare global {
    interface Window {
        initPlumberfinderSearch: any;
        init: any;
    }
}

// google maps (installateursfinder, plumberfinder) integrations
export class map {
    public static isInit: boolean = false;

    constructor() {
        window.initPlumberfinderSearch = function (_element: HTMLElement): void {
            if (!_element) {
                console.warn('Element not found');
                return void 0;
            }

            const _mapsWrapper = document.querySelector(
                '[data-container="' + _element.dataset.supiElement + '"]'
            ) as HTMLElement;

            if (!_mapsWrapper) {
                console.warn('No Google Maps Wrapper Element named found');
                return void 0;
            }

            _element.parentNode?.removeChild(_element);

            const _address = document.querySelector('input#address') as HTMLInputElement;

            if (!map.isInit && _address) {
                _mapsWrapper.classList.remove('d-none');

                map.load(() => {
                    const _place = document.querySelector('input[name="place"]') as HTMLInputElement;
                    const _lat = document.querySelector('input[name="lat"]') as HTMLInputElement;
                    const _lng = document.querySelector('input[name="lng"]') as HTMLInputElement;
                    const _form = _address.parentElement?.parentElement?.parentElement as HTMLFormElement;

                    const autocomplete = new google.maps.places.Autocomplete(_address, {
                        componentRestrictions: { country: ['at'] },
                        types: ['geocode'],
                    });

                    let isKeyDownPressed = false;

                    _address.addEventListener('keydown', (e: any) => {
                        if (!isKeyDownPressed && e.key === 'ArrowDown') {
                            isKeyDownPressed = true;
                        }

                        if ((e.key === 'Enter' || e.key === 'Tab') && !isKeyDownPressed) {
                            e.preventDefault();
                            e.cancelBubble = true;

                            google.maps.event.trigger(e.target, 'keydown', {
                                keyCode: 40,
                            });
                        }
                    });

                    autocomplete.addListener('place_changed', () => {
                        if (
                            autocomplete.getPlace() &&
                            autocomplete.getPlace().geometry &&
                            autocomplete.getPlace().geometry?.location
                        ) {
                            const place: any = autocomplete.getPlace().geometry?.location;

                            _place.value = encodeURIComponent(autocomplete.getPlace().formatted_address ?? '');
                            _lat.value = place.lat();
                            _lng.value = place.lng();

                            _address.focus();
                            _form.submit();
                        }
                    });

                    _form?.addEventListener('submit', (e) => {
                        setTimeout(() => {
                            if (_place.value !== '') {
                                _form.submit();
                            }
                        }, 500);

                        e.preventDefault();
                    });
                });
            }

            if (!map.isInit && document.getElementById('results-plumberfinder')) {
                map.load(async () => {
                    const _elem = document.getElementById('heatpumpFinderMap');
                    const _zip = _elem?.dataset.poi;
                    const _url: URL = new URL(_elem?.dataset.mapUrl ?? '');
                    const geocoder: google.maps.Geocoder = new google.maps.Geocoder();

                    try {
                        await geocoder.geocode(
                            {
                                address: _zip,
                                region: 'at',
                            },
                            (results, status) => {
                                if (status === 'OK' && results) {
                                    const _place = results[0].geometry.location;

                                    if (_url) {
                                        _url.searchParams.append('lng', _place.lng().toString() ?? '');
                                        _url.searchParams.append('lat', _place.lat().toString() ?? '');
                                    }

                                    const searchStringElem = document.querySelector('#result-zip') as HTMLSpanElement;
                                    searchStringElem.innerText = results[0].formatted_address;
                                }
                            }
                        );
                    } catch (e) {
                        const notfound = document.querySelector('.plumberfinder__result-found') as HTMLElement;
                        notfound.innerHTML = notfound.dataset.notfound + ' ' + _zip;
                    }

                    const response = await fetch(_url.href);
                    await response.json().then((data) => {
                        if (!data.pois.length) {
                            return;
                        }

                        const box = new google.maps.LatLngBounds();

                        for (let i = 0; i < data.pois.length; i++) {
                            data.pois[i].pos = new google.maps.LatLng(
                                parseFloat(data.pois[i].lat),
                                parseFloat(data.pois[i].lng)
                            );
                            box.extend(data.pois[i].pos);
                        }

                        const map = new google.maps.Map(document.querySelector('#heatpumpFinderMap') as HTMLElement, {
                            zoom: 14,
                            center: box.getCenter(),
                        });

                        const infoWindow = new google.maps.InfoWindow({
                            content: '',
                            disableAutoPan: true,
                        });

                        const markers = data.pois.map((poi: any) => {
                            const marker = new google.maps.Marker({
                                position: poi.pos,
                                // icon: _icon,
                            });

                            marker.addListener('click', () => {
                                infoWindow.setContent(poi.infoWindowContent);
                                infoWindow.setPosition(poi.pos);
                                infoWindow.open(map, marker);

                                map.panTo(poi.pos);
                            });

                            return marker;
                        });

                        document.querySelectorAll<HTMLButtonElement>('[data-result]').forEach((btn) => {
                            const _id: number = parseInt(btn.dataset.result + '');

                            if (markers[_id]) {
                                btn.addEventListener('click', () => {
                                    google.maps.event.trigger(markers[_id], 'click');
                                });
                            }
                        });

                        const renderer = {
                            // @ts-ignore
                            render({ count, position }, stats) {
                                // change color if this cluster has more markers than the mean cluster
                                const color = count > Math.max(10, stats.clusters.markers.mean) ? '#ff0000' : '#0000ff';
                                // create svg url with fill color
                                const svg = window.btoa(
                                    `<svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240"><circle cx="120" cy="120" opacity=".6" r="70" /><circle cx="120" cy="120" opacity=".3" r="90" /><circle cx="120" cy="120" opacity=".2" r="110" /></svg>`
                                );
                                // create marker using svg icon
                                return new google.maps.Marker({
                                    position,
                                    icon: {
                                        url: `data:image/svg+xml;base64,${svg}`,
                                        scaledSize: new google.maps.Size(60, 60),
                                    },
                                    label: {
                                        text: String(count),
                                        color: 'rgba(255,255,255,0.9)',
                                        fontSize: '16px',
                                    },
                                    title: `Cluster of ${count} markers`,
                                    // adjust zIndex to be above other markers
                                    zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
                                });
                            },
                        };

                        // Add a marker clusterer to manage the markers.
                        new MarkerClusterer({ markers, map, renderer });

                        const lat = _url.searchParams.get('lat');
                        const lng = _url.searchParams.get('lng');

                        // @ts-ignore
                        const latlng = new google.maps.LatLng(lat, lng);
                        map.setCenter(latlng);

                        const _resultCount = document.querySelector('#result-count') as HTMLSpanElement;
                        _resultCount.innerText = data.pois.length;

                        const list = document.querySelector('#plumber-list');
                        data.pois.forEach((v: any, k: number) => {
                            const entry = document.createElement('li');
                            const _address = new DOMParser().parseFromString(v.listEntryContent, 'text/html');
                            const _addressBtn = _address.querySelector('[data-result]') as HTMLButtonElement;
                            _addressBtn.setAttribute('data-result', k.toString());

                            entry.innerHTML = _address.body.innerHTML;

                            list?.append(entry);
                        });

                        document.querySelectorAll<HTMLButtonElement>('[data-result]').forEach((btn) => {
                            const _id: number = parseInt(btn.dataset.result + '');

                            if (markers[_id]) {
                                btn.addEventListener('click', () => {
                                    google.maps.event.trigger(markers[_id], 'click');
                                });
                            }
                        });
                    });
                });
            }

            return void 0;
        };

        const ajax = new lightbox('.js-lightbox-ajax');
        ajax.ajax();
    }

    private static load(cb: any) {
        this.isInit = true;

        let head = document.querySelector('head') as HTMLElement,
            script = document.createElement('script') as HTMLScriptElement;

        script.src =
            '//maps.googleapis.com/maps/api/js?key=AIzaSyAZQJ_wJuIdxCcXk6Zj3dSqzuV3teq49FQ&libraries=places&callback=init';
        script.async = true;
        script.type = 'text/javascript';

        window.init = function () {
            window.init = null;
            cb();
        };

        head.append(script);
    }
}
