import Tab from 'bootstrap/js/dist/tab';

export class tabs {
    constructor() {
        this.tabs();
    }

    tabs(): void {
        // init tabbed content
        const tabListMobile = document.getElementById('nav-tab-mobile') as HTMLSelectElement;
        const tabListDesktop = document.getElementById('nav-tab-desktop') as HTMLUListElement;
        const tabListDesktopTrigger = tabListDesktop?.querySelectorAll('button.nav-link');

        if (tabListMobile) {
            // on mobile show first tab content on load
            let tab = new Tab(tabListMobile[0]);
            tab.show();

            // show content on select change
            tabListMobile.addEventListener('change', () => {
                Array.from(tabListMobile).forEach((el) => {
                    if (el.getAttribute('value') == tabListMobile.value) {
                        let tab = new Tab(el);
                        tab.show();
                    }
                });

                // also change content to current on desktop
                Array.from(tabListDesktopTrigger).forEach((el) => {
                    if (el.getAttribute('data-bs-target') == `#${tabListMobile.value}`) {
                        let tab = new Tab(el);
                        tab.show();
                    }
                });
            });
        }

        // show current content on mobile if changed on desktop
        if (tabListDesktop) {
            tabListDesktopTrigger.forEach((button) => {
                button.addEventListener('click', () => {
                    Array.from(tabListMobile).forEach((el) => {
                        if (el.getAttribute('data-bs-target') == button.getAttribute('data-bs-target')) {
                            let tab = new Tab(el);
                            tab.show();
                            if (el.getAttribute('data-bs-target')) {
                                tabListMobile.value = el.getAttribute('data-bs-target')?.replace('#', '')!;
                            }
                        }
                    });
                });
            });
        }
    }
}
