export class HeatpumpAdvisor {
    private readonly matrix = {
        calculationType: {
            new: ['energy', 'area'],
            existing: ['energy', 'area', 'resource'],
        },
    };

    private readonly path = {
        'calculationType-energy': [
            { heatDemand: 'show' },
            { buildingType: 'hide' },
            { resourceType: 'hide' },
            { resourceAmount: 'hide' },
            { area: 'hide' },
            { persons: 'hide' },
        ],
        'calculationType-area': [
            { heatDemand: 'hide' },
            { buildingType: 'skip:low-energy:constructionType-new' },
            { resourceType: 'hide' },
            { resourceAmount: 'hide' },
            { area: 'show' },
            { persons: 'show' },
        ],
        'calculationType-resource': [
            { heatDemand: 'hide' },
            { buildingType: 'hide' },
            { resourceType: 'show' },
            { resourceAmount: 'show' },
            { area: 'hide' },
            { persons: 'hide' },
        ],
        'buildingType-low-energy': [{ area: 'show' }, { persons: 'show' }],
        'pumpType-heater': [{ withWaterStorage: 'show' }],
        'pumpType-unknown': [{ withWaterStorage: 'show' }],
        'pumpType-monobloc': [{ withWaterStorage: 'hide' }],
        'pumpType-earth': [{ withWaterStorage: 'hide' }],
    };

    constructor() {
        this.imageRadioHandler();
        this.inputChangeHandler();
        this.pathResolver();
        this.prevNextHandler();
        this.windowResizeHandler();
        this.validateStep(document.querySelector('.step.active'));
        this.resetInputFields(document.querySelector('.step.active'), true);
    }

    private getNextSibling(type: string = 'next'): HTMLElement {
        const activeStep = document.querySelector<HTMLElement>('.step.active') as HTMLElement;

        let sibling =
            type === 'next' ? activeStep?.nextElementSibling : (activeStep?.previousElementSibling as HTMLElement);
        while (sibling) {
            if (sibling.matches('.step:not(.d-none):not(.skip)')) {
                return sibling as HTMLElement;
            }

            sibling = type === 'next' ? sibling.nextElementSibling : (sibling.previousElementSibling as HTMLElement);
        }

        return <HTMLElement>document.createElement('div');
    }

    inputChangeHandler(): void {
        const _inputs: NodeListOf<HTMLInputElement> = document.querySelectorAll('input');
        _inputs.forEach((_input: HTMLInputElement) => {
            _input.addEventListener('input', () => {
                let _matrix = null;
                const _value: string = _input.value;
                const _next: string = _input.closest<HTMLElement>('[data-next]')?.dataset.next ?? '';
                const _isSingle: string | undefined =
                    _input.closest<HTMLElement>('[data-next-single]')?.dataset.nextSingle;

                this.validateStep(document.querySelector('.step.active'));

                if (_next === '') {
                    return void 0;
                }

                if (_next in this.matrix) {
                    // @ts-ignore
                    if (_value in this.matrix[_next]) {
                        // @ts-ignore
                        _matrix = this.matrix[_next][_value];
                    }
                }

                if (_matrix !== null) {
                    this.toggleNextElements(_next, _matrix);
                }

                if (_isSingle !== undefined) {
                    this.toggleSingleElement(_next, _value);
                }

                return void 0;
            });
        });
    }

    toggleSingleElement(_next: string, _value: string): void {
        const _nextElement: HTMLElement | null = document.querySelector(`[data-step="${_next}"]`);
        const _hideElements: NodeListOf<HTMLElement> | undefined = _nextElement?.querySelectorAll('[data-value]');
        _hideElements?.forEach((_element: HTMLElement) => {
            _element.classList.add('d-none');
        });

        const _activeElement: NodeListOf<HTMLElement> | undefined = _nextElement?.querySelectorAll(
            `[data-value="${_value}"]`
        );
        _activeElement?.forEach((_element: HTMLElement) => {
            _element.classList.remove('d-none');
        });

        const input = _nextElement?.querySelector('input');
        const placeholderText = document.querySelector(`.${_next}-placeholder:not(.d-none)`);

        input!.value = '1';
        input?.setAttribute('placeholder', placeholderText!.innerHTML.trim());
        input?.setAttribute('max', <string>placeholderText!.getAttribute('data-max-value'));

        return void 0;
    }

    toggleNextElements(_next: string, _matrix: []): void {
        const _nextElement: HTMLElement | null = document.querySelector(`[data-step="${_next}"]`);
        const _nextImageElements: NodeListOf<HTMLElement> | undefined = _nextElement?.querySelectorAll('.step__image');
        _nextImageElements?.forEach((_element: HTMLElement) => {
            _element.classList.add('d-none');
        });

        _matrix.forEach((_element: string) => {
            const _selector: HTMLInputElement | null = document.querySelector<HTMLInputElement>(
                `#${_next}-${_element}`
            );

            if (_selector) {
                _selector.closest<HTMLElement>('.step__image')?.classList.remove('d-none');
            }
        });
    }

    pathResolver(): void {
        const _inputs: NodeListOf<HTMLInputElement> = document.querySelectorAll('input[type="radio"]');
        _inputs.forEach((_input: HTMLInputElement) => {
            _input.addEventListener('change', () => {
                if (_input.id in this.path) {
                    // @ts-ignore
                    const _step = this.path[_input.id];

                    _step.forEach((_item: object) => {
                        for (const [key, value] of Object.entries(_item)) {
                            const _nextElement: HTMLElement | null = document.querySelector(`[data-step="${key}"]`);
                            const _value = value.split(':')[0];
                            const _preSelect = value.split(':')[1] ?? null;
                            const _parent = value.split(':')[2] ?? null;

                            switch (_value) {
                                case 'show':
                                    _nextElement?.classList.remove('d-none');
                                    _nextElement?.classList.remove('skip');
                                    break;
                                case 'hide':
                                    _nextElement?.classList.add('d-none');
                                    _nextElement?.classList.remove('skip');
                                    break;
                                case 'skip':
                                    const _check = document.getElementById(_parent) as HTMLInputElement;

                                    if (_check.checked) {
                                        _nextElement?.classList.add('skip');
                                        const input = _nextElement?.querySelector('input');
                                        input!.checked = true;
                                    }

                                    _nextElement?.classList.remove('d-none');
                                    const _selector: HTMLInputElement | null = document.querySelector<HTMLInputElement>(
                                        `#${key}-${_preSelect}`
                                    );

                                    _selector?.click();

                                    break;
                            }
                        }
                    });
                }

                return void 0;
            });
        });
    }

    prevNextHandler(): void {
        const prevNext = document.querySelectorAll('.prevNext') as NodeListOf<HTMLButtonElement>;

        prevNext.forEach((button) => {
            button.addEventListener('click', () => {
                const activeStep = document.querySelector<HTMLElement>('.step.active');
                const parent = activeStep?.parentElement;
                const firstElement = parent?.firstElementChild as HTMLElement;
                const lastElement = parent?.lastElementChild as HTMLElement;
                const nextStep = this.getNextSibling(button?.id);
                const _progressBar = document.querySelector('.progress-bar__progress') as HTMLElement;

                activeStep?.classList.remove('active');
                nextStep?.classList.add('active');

                if (nextStep === lastElement && button.id === 'next') {
                    button.classList.add('d-none');
                    document.getElementById('advisorSubmitButton')?.classList.remove('d-none');
                    this.collectSummary();
                    _progressBar.style.setProperty('--progress', '100%');
                } else {
                    button.classList.remove('d-none');
                    document.getElementById('advisorSubmitButton')?.classList.add('d-none');
                    _progressBar.style.removeProperty('--progress');
                }

                if (button.id === 'prev') {
                    nextStep === firstElement ? button.classList.add('d-none') : button.classList.remove('d-none');
                    if (activeStep === lastElement) {
                        document.getElementById('next')?.classList.remove('d-none');
                        lastElement.querySelector('.summary')?.replaceChildren();
                    }
                    this.validateStep(nextStep);
                    this.elDisablingHandler();
                    this.resetInputFields(activeStep);
                }

                if (button.id === 'next') {
                    document.getElementById('prev')?.classList.remove('d-none');
                    this.elDisablingHandler();
                    this.validateStep(nextStep);
                }

                this.progress();
                this.scrollToStep(nextStep);

                return void 0;
            });
        });
    }

    imageRadioHandler(): void {
        const imageRadios = document.querySelectorAll<HTMLElement>('.step__image');
        imageRadios.forEach((imageRadio) => {
            imageRadio.addEventListener('click', () => {
                const input = imageRadio.querySelector<HTMLInputElement>('input');
                input?.click();
            });
        });
    }

    validateStep(step: Element | null): boolean {
        if (step) {
            const _inputsToCheck: NodeListOf<HTMLInputElement> | undefined = step?.querySelectorAll('input');
            const nextBtn = document.getElementById('next') as HTMLButtonElement;

            let stepIsValid = true;
            _inputsToCheck?.forEach((_element: HTMLInputElement) => {
                stepIsValid = _element.checkValidity();
            });

            nextBtn!.disabled = !stepIsValid;

            return stepIsValid;
        } else {
            return false;
        }
    }

    resetInputFields(target: Element | null, fromSummary: boolean = false) {
        let inputs: NodeListOf<HTMLInputElement> | undefined;

        if (fromSummary) {
            let nextStep = target?.nextElementSibling;
            while (nextStep) {
                if (!nextStep.classList.contains('d-none') && !nextStep.classList.contains('skip')) {
                    resetInput(nextStep);
                }

                nextStep = nextStep.nextElementSibling;
            }
        } else {
            resetInput(target);
        }

        function resetInput(step: Element | null) {
            inputs = step?.querySelectorAll('input');

            inputs?.forEach((input) => {
                if (input.type === 'radio') {
                    input.checked = false;
                } else {
                    input.value = '';
                }
            });
        }

        return target;
    }

    collectSummary(): void {
        const _visibleElements: NodeListOf<HTMLInputElement> = document.querySelectorAll(
            '.step:not(.d-none):not(.skip) input'
        );
        let _selectedItems: { key: string; value: string; jumpTo: string; unit: string }[] = [];

        _visibleElements.forEach((_element) => {
            const type: string = _element.id.split('-')[0];
            // @ts-ignore
            const key = TYPO3.lang[type];
            let value = '';
            let unit = '';

            if (_element.type === 'radio' && _element.checked) {
                // @ts-ignore
                value = TYPO3.lang[type + '.' + _element.value];
            }

            if (_element.type !== 'radio' && _element.value !== '0') {
                value = _element.value;

                if (type === 'resourceAmount') {
                    const selectedResource: HTMLInputElement | null = document.querySelector(
                        '.step-resource-type input:checked'
                    );
                    unit = _element.getAttribute(`data-${selectedResource?.value}-unit`) || '';
                } else {
                    unit = _element.getAttribute('data-unit') || '';
                }
            }

            if (key !== '' && value !== '') {
                _selectedItems.push({
                    key: key,
                    value: value,
                    jumpTo: type,
                    unit: unit,
                });
            }
        });

        if (_selectedItems.length > 0) {
            console.log(_selectedItems);

            this.createSummaryView(_selectedItems);
        }
    }

    createSummaryView(_items: { key: string; value: string; jumpTo: string; unit: string }[]) {
        const _insertInto = document.getElementById('summary');
        _insertInto?.replaceChildren();

        _items.forEach((_item) => {
            // @ts-ignore
            const jumpToLabel = TYPO3.lang['btn.change'];
            const listItem = document.createElement('li');
            listItem.classList.add('summary__item');
            listItem.classList.add('p-hg');
            listItem.innerHTML = `
                <span class="summary__selected">
                    <span class="summary__key">${_item.key}</span>
                    <span class="summary__value">${_item.value} ${_item.unit}</span>
                </span>
                <button type="button" class="summary__edit | btn btn-link" data-jumpto="${_item.jumpTo}">${jumpToLabel}</button>
            `;

            _insertInto?.appendChild(listItem);
        });

        const jumpToButton = document.querySelectorAll('.summary__edit');
        jumpToButton.forEach((_btn) => {
            _btn.addEventListener('click', () => {
                const activeStep = document.querySelector<HTMLElement>('.step.active');
                const parent = activeStep?.parentElement;
                const firstElement = parent?.firstElementChild as HTMLElement;
                const nextStep = parent?.querySelector(`.step[data-step="${_btn.getAttribute('data-jumpto')}"`);

                document.getElementById('next')?.classList.remove('d-none');
                document.getElementById('advisorSubmitButton')?.classList.add('d-none');

                if (nextStep === firstElement) {
                    document.getElementById('prev')?.classList.add('d-none');
                }

                activeStep?.classList.remove('active');
                nextStep?.classList.add('active');

                const _progressBar = document.querySelector('.progress-bar__progress') as HTMLElement;
                _progressBar.style.removeProperty('--progress');

                this.progress();
                this.resetInputFields(nextStep!, true);
                this.elDisablingHandler();
                this.validateStep(nextStep!);

                this.scrollToStep(nextStep!);
            });
        });
    }

    elDisablingHandler(): void {
        const _hiddenElements: NodeListOf<HTMLInputElement> = document.querySelectorAll('.step.d-none input');
        const _showElements: NodeListOf<HTMLInputElement> = document.querySelectorAll('.step:not(.d-none) input');

        _hiddenElements.forEach((_element: HTMLInputElement) => {
            _element.disabled = true;
        });

        _showElements.forEach((_element: HTMLInputElement) => {
            _element.disabled = false;
        });
    }

    progress(): void {
        const _visibleElements = Array.prototype.slice.call(document.querySelectorAll('.step:not(.d-none)'));
        const _activeElement = document.querySelector('.step.active') as HTMLElement;
        const _countAll = _visibleElements.length;
        const _index = _visibleElements.indexOf(_activeElement);
        const _progress = (_index * 100) / _countAll;
        const _progressBar = document.querySelector('.progress-bar') as HTMLElement;
        _progressBar.style.setProperty('--progress', _progress + '%');
    }

    windowResizeHandler(): void {
        window.addEventListener('resize', function () {
            const activeStep = document.querySelector('.step.active');
            activeStep?.scrollIntoView({
                behavior: 'auto',
                block: 'nearest',
                inline: 'center',
            });
        });
    }

    scrollToStep(step: Element): void {
        step?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center',
        });

        let timer: number | null | undefined = null;
        document.querySelector('.steps')?.addEventListener(
            'scroll',
            function () {
                if (timer !== null) {
                    clearTimeout(timer);
                }
                timer = setTimeout(function () {
                    step?.querySelector('input')?.focus();
                }, 150);
            },
            false
        );
    }
}
