export class navigation {
    constructor() {
        this.main();
        this.mobileSticky();
        this.jumpTo();
    }

    main(): void {
        const mainNavigation = document.getElementById('nav-main');

        mainNavigation?.addEventListener('show.bs.collapse', (event) => {
            let elem = event.target as HTMLElement;
            let parent = elem.parentElement as HTMLElement;
            elem.classList.remove('d-none');
            elem.style.setProperty('--main-navigation-height', parent.offsetHeight + 'px');
            document.getElementById('top')?.classList.add('nav-open');
        });

        mainNavigation?.addEventListener('hide.bs.collapse', () => {
            document.getElementById('top')?.classList.remove('nav-open');
        });
    }

    mobileSticky(): void {
        const stickyHeader = document.querySelector('body:not(.tmpl-Landingpage) #header') as HTMLElement;
        const waitTillNavFadesIn = 3000;
        let lastScroll = window.scrollY;
        let isScrolling: any;
        let scrollOffset = 52;
        if (window.innerWidth > 1200) scrollOffset = 0;

        if (stickyHeader) {
            document.addEventListener('scroll', () => {
                const currentScroll = window.scrollY;

                if (currentScroll > scrollOffset) {
                    stickyHeader.classList.add('shadow', 'shadow-xl-none');
                    stickyHeader.classList.remove('top');
                } else {
                    stickyHeader.classList.remove('shadow', 'shadow-xl-none');
                    stickyHeader.classList.add('top');
                }

                if (currentScroll < lastScroll) {
                    stickyHeader.classList.add('sticky-visible');
                } else {
                    stickyHeader.classList.remove('sticky-visible');
                }

                lastScroll = currentScroll;

                clearTimeout(isScrolling);

                isScrolling = setTimeout(() => {
                    if (window.scrollY > 0) {
                        stickyHeader.classList.add('sticky-visible');
                    }
                }, waitTillNavFadesIn);
            });
        }
    }

    jumpTo(): void {
        // jump-to scroll-margin fix
        // the "normal" scroll-margin css property seem to work only on headings, not on ids on div elements (in our case)
        let scrollOffset = 143;
        if (window.innerWidth > 1200) scrollOffset = 218;
        document.querySelectorAll<HTMLElement>('#nav-jumpto a').forEach((el) => {
            el?.addEventListener('click', (e) => {
                e.preventDefault();
                const _id = el.getAttribute('href');
                if (_id) {
                    const _jumpToElement = document.getElementById(_id.substring(1));

                    if (_jumpToElement) {
                        const y = _jumpToElement.getBoundingClientRect().top + scrollY - scrollOffset;
                        window.scrollTo({ top: y, behavior: 'smooth' });
                        // _jumpToElement.scrollIntoView({ behavior: 'smooth', block: "center", inline: 'start' });
                    }
                }
            });
        });

        // scroll in jumpto navigation to active entry
        const jumpToList = document.getElementById('navigation-jumpto') as HTMLUListElement;
        const config = { attributes: true, subtree: true };
        const callback = (mutationList: MutationRecord[]) => {
            mutationList.forEach((mutation: MutationRecord) => {
                if (mutation.type === 'attributes') {
                    const el = mutation.target as HTMLElement;
                    if (el.classList.contains('active')) {
                        jumpToList.scrollLeft +=
                            el.getBoundingClientRect().left - (window.innerWidth / 2 - el.offsetWidth / 2);
                    }
                }
            });
        };

        if (jumpToList) {
            const observer = new MutationObserver(callback);
            observer.observe(jumpToList, config);
        }
    }
}
