import { Supi } from '../../../../../vendor/supseven/supi/Build/Src/JavaScript/Supi';

// import Tooltip from 'bootstrap/js/dist/tooltip';
// import Modal from 'bootstrap/js/dist/modal';
// import debounce from 'lodash.debounce';

import { lightbox } from './components/lightbox';
import { animations } from './components/animations';
import { map } from './components/map';
import { tabs } from './components/tabs';
import { search } from './components/search';
import { navigation } from './components/navigation';
import { video } from './components/video';
import { HeatpumpAdvisor } from './components/heatpumpAdvisor';
import { FormToggle } from './components/formToggle';
import { sliders } from './components/Sliders';

// Global imports
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/scrollspy';
import '../../node_modules/iframe-resizer/js/iframeResizer.js';
import Carousel from 'bootstrap/js/dist/carousel';

function init() {
    (window as any).supi = new Supi();

    // navigations
    new navigation();

    // start animations
    new animations();

    //search functionality
    new search();

    // native sliders
    new sliders();

    // youtube EXT:supi integrations
    const youtube = new lightbox('.js-youtube');
    youtube.youtube();

    // image lightboxes
    const imageLightbox = new lightbox('.lightbox');
    imageLightbox.image();

    // downloads lightboxes
    const downloadsLightbox = new lightbox('.dl-lightbox');
    downloadsLightbox.downloads();

    // language / node switcher
    const languageSelectors = ['node-select-menu', 'node-select'];

    for (const languageSelector of languageSelectors) {
        document.getElementById(languageSelector)?.addEventListener('change', (event) => {
            const _elem = event.srcElement as HTMLSelectElement;
            window.location.href = _elem.value;
        });
    }

    // iframe resizer
    if (document.querySelectorAll<HTMLIFrameElement>('.frame-type-tx_supi_embed iframe.resize').length > 0) {
        // @ts-ignore
        iFrameResize();
    }

    // init carousels on mobile devices for swipe gestures
    if ('ontouchstart' in document.documentElement) {
        const carouselItem = document.querySelector('#carousel') as HTMLElement;
        if (carouselItem) {
            new Carousel(carouselItem);
        }
    }

    // tabs responsive behavior
    new tabs();

    // @TODO: rebuild this properly - this is from the old project
    // $(document).on('blur', '[data-toggle=factoryNumberValidation]', function (e) {
    //     var el = $(e.target),
    //         val = (el.val() || '').replace(/\s+$/, '');
    //
    //     if (!/^\w?[\s\d]+\d$/i.test(val)) {
    //         val = '';
    //     }
    //
    //     el.val(val);
    // });

    new map();

    // mobile pagination
    const paginationMobileSelect = document.getElementById('pagination-select') as HTMLSelectElement;
    if (paginationMobileSelect) {
        paginationMobileSelect.addEventListener('change', () => {
            window.location.href = paginationMobileSelect.value;
        });
    }

    new video();

    // heatpumpadvisor
    new HeatpumpAdvisor();

    // form handling stuff
    const formHandler = new FormToggle();
    formHandler.toggleHandler('partnerclubpoints', true);
    formHandler.toggleHandler('gutscheinaktion_x5_01', false);
    formHandler.toggleHandler('messeWels', true);
    formHandler.jumpToOnFeedback('.form');
}

if (['complete', 'interactive', 'loaded'].includes(document.readyState)) {
    init();
} else {
    document.addEventListener('DOMContentLoaded', () => init());
}

window.addEventListener('resize', () => {
    document.getElementById('top')?.classList.remove('nav-open');
});

// remove d-none class from maps element, when maps are allowed
window.addEventListener('serviceCallback', (event) => {
    const _parent = event.target as HTMLElement;
    const _mapElem = _parent.querySelector('.plumberfinder-map__wrapper');

    if (_mapElem) {
        _mapElem.classList.remove('d-none');
    }
});
