import * as basicScroll from 'basicscroll';

export class animations {
    constructor() {
        this.hero();
        this.animate();
        this.counter();
    }

    hero(): void {
        let heroElem = document.getElementById('hero-text');
        let startingPoint = window.innerHeight <= 1440 ? '0-bottom' : '-100-bottom';
        let endPoint = window.innerHeight <= 1440 ? 'middle-middle' : 'bottom-middle';

        if (heroElem) {
            let posY = heroElem.offsetHeight / 2;

            const hero = basicScroll.create({
                elem: document.getElementById('hero-text'),
                from: startingPoint,
                to: endPoint,
                props: {
                    '--hero-text-position-y': {
                        from: posY + 'px',
                        to: 0,
                    },
                },
            });

            hero.start();
        }
    }

    animate(): void {
        const _elements: NodeList = document.querySelectorAll('[data-animation]');
        const _animationObserver = new IntersectionObserver(
            function (_items) {
                _items.forEach((_item) => {
                    if (!_item.isIntersecting) {
                        if (_item.boundingClientRect.top > 0) {
                            _item.target.classList.remove('animated');
                        }
                    } else {
                        _item.target.classList.add('animated');
                    }
                });
            },
            { rootMargin: '0px 0px -10% 0px' }
        );

        Array.from(_elements as NodeListOf<HTMLElement>).forEach((_element: HTMLElement) => {
            _animationObserver.observe(_element);
        });
    }

    counter(): void {
        const _reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');

        if (!_reducedMotion || _reducedMotion.matches) {
            return;
        }

        const _counterAnim = (element: HTMLElement, start: number = 0, end: number | null, duration: number = 1000) => {
            let startTimestamp: number | null = null;

            const step = (timestamp: number) => {
                if (!startTimestamp) {
                    startTimestamp = timestamp;
                }

                const progress = Math.min((timestamp - startTimestamp) / duration, 1);
                element.innerText = '' + Math.floor(progress * (end ?? 0 - start) + start);

                if (progress < 1) {
                    window.requestAnimationFrame(step);
                }
            };

            window.requestAnimationFrame(step);
        };

        const _elements = document.querySelectorAll('[data-count-wrapper]');

        const _countObserver = new IntersectionObserver(function (_items) {
            _items.forEach((_item) => {
                const _countElem = _item.target.querySelectorAll('[data-count]') as NodeList;

                Array.from(_countElem as NodeListOf<HTMLElement>).forEach((_elem: HTMLElement) => {
                    const _targetElement = _elem.querySelector('.facts-figures__value') as HTMLElement;
                    let _end = 0;

                    if (_elem.dataset.count) {
                        _end = parseInt(_elem.dataset.count);
                    }

                    if (_targetElement) {
                        _counterAnim(_targetElement, 0, _end);
                    }
                });
            });
        });

        Array.from(_elements as NodeListOf<HTMLElement>).forEach((_element: HTMLElement) => {
            _countObserver.observe(_element);
        });
    }
}
